




























import Vue from 'vue'
import Component from 'vue-class-component'
import EditorJS, { OutputData } from '@editorjs/editorjs'
import Header from '@editorjs/header'
import Table from '@editorjs/table'
import SimpleImage from '@editorjs/simple-image'
import Warning from '@editorjs/warning'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import List from '@editorjs/list'
import Link from '@editorjs/link'
import Embed from './custom-editorjs-plugins/embed/index'
import CheckList from '@editorjs/checklist'
import InlineConde from '@editorjs/inline-code'
import UnderLine from '@editorjs/underline'
import Delimiter from '@editorjs/delimiter'
import TextVariantTune from '@editorjs/text-variant-tune'
import NestedList from '@editorjs/nested-list'
import { ArticleService } from '@/includes/services/ArticleService'
//@ts-ignore
import he from 'he'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { Guid } from 'guid-typescript'
import { errorNotification } from '@/includes/services/NotificationService'

@Component({
  components: { CenteredColumnLayout }
})
export default class ArticleReadOnly extends Vue {

  articleTitle: string = ''

  content: { content: OutputData | string, type: string } = {
    content: '',
    type: ''
  }

  contentTrig: boolean = false

  editor: EditorJS | null = null

  isLoading = false

  checkTelegramLinks(text: string): string {
    let regexTChannel = new RegExp(/(t|telegram).me\/([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
    let map = new Map

    text = text.replace(regexTChannel, match => this.checkGuidInText(match, map))

    for (let url of map) {
      let key = url[0] as string
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="https://' + key + '">' + key + '</a>')
    }

    return text
  }

  replaceTelegramUsers(text: string): string {
    let regexTName = new RegExp(/@([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g)
    let map = new Map

    text = text.replace(regexTName, match => this.checkGuidInText(match, map))

    for (let url of map) {
      let key = url[0] as string
      let urlName = key.slice(1, key.length)
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="https://t.me/' + urlName + '">' + key + '</a>')
    }

    return text
  }

  checkGuidInText(match: string, map: Map<string, string>): string {
    let guid = Guid.create().toString()

    if (map.has(match)) {
      return map.get(match)!
    } else {
      map.set(match, guid)
      return guid
    }
  }

  replaceText(text: string): string {
    const regex = /(?<!href\s*=\s*["']?)https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi
    let urlMap = new Map as Map<string, string>
    text = text.replace('\n', '<br>')
    text = text.replace(regex, match => this.checkGuidInText(match, urlMap))
    text = this.checkTelegramLinks(text)
    text = this.replaceTelegramUsers(text)
    for (let url of urlMap) {
      let key = url[0]
      let clearKey = key.replace(/<br>/g, '')
      let value = url[1]
      let newRegex = new RegExp(value, 'g')
      text = text.replace(newRegex, '<a target="_blank" href="' + clearKey + '">' + key + '</a>')
    }
    return text
  }

  async fetchConfig(): Promise<void> {
    this.isLoading = true
    try {
      let data: { content: OutputData | string, type: string } = await ArticleService.getArticleRequest('tg', { id: this.$route.params.articleKey })
      this.content = data
      if (data) {
        if (data.type == 'data') {
          this.editor = new EditorJS({
            holder: 'editorjs',
            tools: {
              header: Header,
              table: Table,
              simpleImage: SimpleImage,
              warning: Warning,
              quote: Quote,
              marker: Marker,
              list: List,
              embed: Embed as any,
              checkList: CheckList,
              inlineCode: InlineConde,
              underLine: UnderLine,
              delimiter: Delimiter,
              textVariant: TextVariantTune,
              nestedList: NestedList,
              link: {
                class: Link,
                config: {
                  endpoint: 'https://api.presscode.info/cabinet/v1/tg/geturlinfo'
                }
              },
            },
            readOnly: true,
            tunes: [ 'textVariant' ]
          })
          this.editor.isReady.then(() => {
            this.editor!.render(data.content as OutputData)
          })
        } else if (data.type == 'html') {
          data.content = (data.content as string).replace(/\n/g, ' <br> ')
          data.content = this.replaceText(data.content)
          new Promise(resolve => resolve(data)).then(() => {
            (this.$refs.container as HTMLElement).getElementsByClassName('html-text')[0].innerHTML = data.content as string
          })
        }
      }
    } catch (error: any) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  mounted(): void {
    this.fetchConfig()
  }
}
